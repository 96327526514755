<template>

  <div class="mx-1" id="temp3-giftcards">

    <!-- BREADCRUMB -->
<!--    <breadcrumb-base :breadcrumb="breadcrumb" :title="'GiftCards'">-->
<!--    </breadcrumb-base>-->



    <div class="py-2">

      <div class="flex-row flex justify-end" style="place-items: center;">
        <a-z-pagination v-model="selectedAlphaCurrentX"
                        @clear="clearSelectedAlpha"
                        v-on:change="pageChanged"></a-z-pagination>
      </div>

      <transition name="fade">
        <div class="flex flex-col w-full">
          <vs-input class="my-3 px-2 w-full is-label-placeholder" icon-pack="feather" icon="icon-search"
                    :placeholder="$t('Search')" v-model="searchText"/>

          <div class="flex flex-row flex-wrap w-full ">
            <div
              class="w-1/2 md:w-1/5 lg:w-1/7 my-2 flex flex-wrap px-2"
              v-for="(card, index) in giftCards">
              <div
                @click="onProductSelected(card)"
                class="bg-white shadow-lg rounded-lg w-full flex flex-row justify-between items-center text-center py-4 px-1"
              >
                <img class="card-image" :src="card.product_image" :alt="card.product_name"/>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!--    Empty List State -->
      <transition name="fade">
        <div class="py-6 flex w-full bg-img" v-if="giftCards.length === 0 && !errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--            <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                 class="mx-auto mb-4 max-w-full">-->
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
          </div>
        </div>
      </transition>

      <!--    Error List State-->
      <transition name="fade">
        <div class="py-6 flex w-full bg-img" v-if="errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                 class="mx-auto mb-4 max-w-full">
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
          </div>
        </div>
      </transition>

    </div>


    <div>
      <bottom-modal v-if="showProductDialog" @close="onCloseProductDialog">
        <template slot="body" >
          <div class="p-6 flex flex-row flex-centered" v-if="!showPanInput">
            <div class="flex flex-col justify-center col-mx-auto  w-full">
              <div class="flex justify-center items-center">
                <img class="modal-card-image" :src="'https://pjtelesoftgmbh.de' + selectedProduct.product_image" :alt="selectedProduct.product_name"/>
              </div>
              <div class="flex flex-row flex-wrap w-full py-2">
                <div
                  class="w-1/2 md:w-1/3 lg:w-1/5 my-2 flex flex-wrap px-2"
                  v-for="(product, index) in selectedProduct.product_configurations">
                  <div
                    :class="{ 'product-selected': selectedConfigIndex == index }"
                    @click="setProductConfigSelected(index)"
                    class="bg-white shadow-lg cursor-pointer rounded-lg w-full flex flex-row justify-between items-center text-center py-4 px-1"
                  >
                    <h1 class="mx-3 item-card-text">{{ product.value | germanNumberFormat }}</h1>
                    <h3 class="mx-3 item-card-profit">{{ $t('Profit') }} {{  calculateProfit(product.value, product.discount)   | germanNumberFormat}}</h3>
                  </div>
                </div>
              </div>
              <div class="modal-content py-2 px-10 vx-row flex justify-center" >

                <vs-input size="large" class=" md:w-1/2 custom-input text-left"
                          validate-on="change"
                          :label="$t('EnterBachNo')"
                          :placeholder="$t('BatchNo')"
                          v-model="giftCardBatchNo"/>

              </div>
              <div class="p-2"></div>
              <div class="flex justify-evenly w-full card-billing-text">
                <div class="vx-row">
                  <p>{{ $t('TotalBill') }} <span class="card-billing-info">{{ totalBill | germanNumberFormat }}</span>
                  </p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('Discount') }} <span class="card-billing-info">{{ discount }} %</span>
                  </p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('Profit') }} <span class="card-billing-info">{{ profit | germanNumberFormat }}</span></p>
                </div>
                <div class="vx-row" v-if="activeUserInfo.mask_seller_discount != 1">
                  <p>{{ $t('BuyingPrice') }} <span class="card-billing-info">{{ (totalBill - profit )| germanNumberFormat }}</span></p>
                </div>
              </div>

            </div>
            <div class="flex-col flex justify-center w-48 ml-5">
              <vs-button size="large" class="my-2 bg-temp3-success" color="success" @click="purchaseCard()">
                <strong>{{ $t('Activate') }}</strong>
              </vs-button>
              <vs-button size="large" class="my-2" color="danger" @click="onCloseProductDialog()">
                <strong>{{
                    $t('Close')
                  }}</strong>
              </vs-button>

            </div>
          </div>
        </template>
      </bottom-modal>
    </div>

    <vx-modal v-if="showPurchaseDataModal" component-class="modal-container-7">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="max-height: 500px; overflow-y: auto;">
            <div class="text-center status-card" v-if="purchaseData.message_type == 1">

              <div v-if="purchaseData">
                <receipt-print
                  v-on:close="onCloseProductDialog()"
                  ref="printCard"
                  :print-report-type="4" :purchase-data="purchaseData"></receipt-print>
              </div>
            </div>
            <div class="text-center status-card" v-if="purchaseData.message_type == 0">
              <div class="status-image">
                <svg style="margin: auto;display: block" xmlns="http://www.w3.org/2000/svg" width="241.43" height="241.43" viewBox="0 0 241.43 241.43">
                  <g id="Group_5694" data-name="Group 5694" transform="translate(-284 -284)">
                    <path id="Path_2360" data-name="Path 2360" d="M404.715,284C338.242,284,284,338.251,284,404.715S338.251,525.43,404.715,525.43,525.43,471.178,525.43,404.715,471.178,284,404.715,284Zm0,227.284A106.569,106.569,0,1,1,511.284,404.715,106.689,106.689,0,0,1,404.715,511.284Zm0,0" fill="#ff000d"/>
                    <path id="Path_2361" data-name="Path 2361" d="M435,456.219A21.219,21.219,0,1,0,456.219,435,21.244,21.244,0,0,0,435,456.219Zm21.219-7.073a7.073,7.073,0,1,1-7.073,7.073,7.082,7.082,0,0,1,7.073-7.073Zm0,0" transform="translate(-79.797 -79.797)" fill="#ff000d"/>
                    <path id="Path_2362" data-name="Path 2362" d="M576.219,435a21.219,21.219,0,1,0,21.219,21.219A21.244,21.244,0,0,0,576.219,435Zm0,28.293a7.073,7.073,0,1,1,7.073-7.073,7.082,7.082,0,0,1-7.073,7.073Zm0,0" transform="translate(-143.212 -79.797)" fill="#ff000d"/>
                    <path id="Path_2363" data-name="Path 2363" d="M431.22,377.072a7.069,7.069,0,0,0-10,0l-14.146,14.146a7.072,7.072,0,0,0,10,10l14.146-14.146a7.069,7.069,0,0,0,0-10Zm0,0" transform="translate(-63.943 -48.09)" fill="#ff000d"/>
                    <path id="Path_2364" data-name="Path 2364" d="M627.074,377.072a7.072,7.072,0,0,0-10,10l14.146,14.146a7.072,7.072,0,0,0,10-10Zm0,0" transform="translate(-174.92 -48.09)" fill="#ff000d"/>
                    <path id="Path_2365" data-name="Path 2365" d="M442.073,555a7.073,7.073,0,0,0,0,14.146,49.568,49.568,0,0,1,49.512,49.512,7.073,7.073,0,1,0,14.146,0A63.731,63.731,0,0,0,442.073,555Zm0,0" transform="translate(-79.797 -143.212)" fill="#ff000d"/>
                  </g>
                </svg>
              </div>
              <h4>{{$t('TransactionFailed')}}</h4>
              <h3>{{ purchaseData.message }}</h3>
            </div>
          </div>
          <div class="vs-row flex justify-center mt-3" v-if="purchaseData.message_type != 1">
            <vs-button class="" color="primary" @click="onCloseProductDialog()">{{ $t('Close') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>

</template>

<script>
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'
import AZPagination from "@/components/template3/AZPagination";
import BottomModal from "@/components/template3/BottomModal";

export default {
  name: 'GiftCards',
  components: {
    BottomModal,
    AZPagination,
    ReceiptPrint,
    VxModal,
  },
  data() {
    return {
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Gift Cards', i18n: 'GiftCards', active: true },
      ],
      searchText: '',
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      selectedProduct: null,
      showProductDialog: false,
      totalBill: 0,
      discount: 0,
      profit: 0,
      selectedConfigIndex: null,
      giftCardBatchNo: '',
      showPanInput: false,
      showPurchaseDataModal: false,
      purchaseData: null,
    }
  },
  computed: {
    giftCards() {
      return this.$store.state.giftCards.giftCards.filter((c) => c.product_name.toLowerCase().startsWith(this.searchText.toLowerCase()) )
    },
  },
  methods: {
    onProductSelected(p) {
      this.selectedProduct = p
      console.log(this.selectedProduct)
      this.showProductDialog = true
    },
    onCloseProductDialog() {
      this.showPurchaseDataModal = false
      this.showProductDialog = false
      this.selectedProduct = null
      this.showPanInput = false
      this.selectedConfigIndex = null
      this.giftCardBatchNo = ''
      this.purchaseData = null
      this.showPurchaseDataModal = false
      this.totalBill = 0
      this.discount = 0
      this.profit = 0
      this.fetchAllGiftCards()
    },
    setProductConfigSelected(index) {
      if (this.selectedConfigIndex == index) {
        this.selectedConfigIndex = null
      } else {
        this.selectedConfigIndex = index
      }
      this.calculateBill()
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.searchText = ''
    },
    calculateBill() {
      if (this.selectedConfigIndex == null) {
        this.totalBill = 0
        this.discount = 0
        this.profit = 0
      } else {
        this.totalBill = Number(this.selectedProduct.product_configurations[this.selectedConfigIndex].value)
        this.discount = Number(this.selectedProduct.product_configurations[this.selectedConfigIndex].discount)
        const percent = ((this.totalBill) * (this.discount / 100)).toFixed(2)
        this.profit = percent
      }
    },
    calculateProfit(amount, commission) {
      return (Number(amount) * (Number(commission) / 100)).toFixed(2)
    },
    showEntryOptions() {
      this.showPanInput = true
    },
    purchaseCard() {
      if (this.giftCardBatchNo == '') {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill batch no',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if(this.selectedConfigIndex == null) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select Denomination',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const data = {
        product_configuration_id: this.selectedProduct.product_configurations[this.selectedConfigIndex].id,
        card_type: 1,
        pan_card: this.giftCardBatchNo,
        quantity: 1,
      }
      this.showProductDialog = false
      this.$vs.loading()
      this.$store.dispatch('giftCards/purchaseCard', data)
        .then((data) => {
          this.$vs.loading.close()
          this.purchaseData = data
          this.showPurchaseDataModal = true
          this.checkPrintCardPop()
        })
        .catch((error) => {
          console.log(error)
          this.onCloseProductDialog()
          this.$vs.loading.close()
          if (error.response.data) {
            this.purchaseData = error.response.data
            this.showPurchaseDataModal = true
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    checkPrintCardPop() {
      const showPrintPop = localStorage.getItem('show_print_pop') || 'false'
      if (showPrintPop != 'true') {
        setTimeout(() => {
          this.$refs.printCard.printCard()
        }, 1000)
      }
    },
    fetchAllGiftCards() {
      this.$vs.loading()
      this.$store.dispatch('giftCards/fetchGiftCards', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  created() {
    this.fetchAllGiftCards()
  },
}
</script>

<style lang="scss">
#temp3-giftcards {
  .vs-input--label {
    font-size: 14px;
    font-weight: bold;
  }

  .card-image {
    max-height: 48px;
    object-fit: cover;
    margin: auto;
  }

  .modal-card-image {
    max-height: 62px;
  }

  .item-card-text {
    font-size: 40px;
    font-weight: bolder;
  }

  .item-card-amount {
    font-size: 36px;
    padding: 0 10px;
  }

  .card-billing-text {
    font-size: 24px;
    font-weight: bold;
  }

  .card-billing-info {
    font-weight: normal !important;
  }
  .item-card-text {
    font-size: 28px;
    font-weight: bolder;
  }
  .item-card-profit {
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    margin: auto;
    color: #2c2c2c;
  }
  .product-selected {
    background-color: #44D7B6 !important;
    color: #ffffff !important;
  }
  .product-selected .item-card-profit {
    color: #ffffff !important;
  }
  .product-selected .item-card-text {
    color: #ffffff !important;
  }

  .gift-card-title {
    font-size: 2.5rem;
    color: #0000008a;
  }
  p.gift-card-amount {
    margin: auto;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bolder;
    color: black;
  }
  img.gift-card-image {
    width: 200px;
    margin: auto;
  }
}

</style>
